import React from "react";
import "./AdvantagesStyles.scss";
import Logo3d from "../assets/GL-3d-logo.png";
import { Users, Handshake, BellRinging } from "phosphor-react";
import AnimatedImage from "./AnimatedImage";
import SlideIn from "./SlideIn.js";
import { useTranslation } from "react-i18next";

function Advantages() {
	const { t } = useTranslation();
	const handleButtonClick = () => {
		window.location.href = "/calculate";
	};

	return (
		<section id="advantages" className="advantages">
			<div className="advantages-heading">
				<SlideIn direction="up" delay={100} threshold={0.1}>
					<h1>{t("advantages.title")}</h1>
				</SlideIn>
			</div>
			<div className="advantages-text-logo">
				<div className="texts-benefits">
					<div className="benefits-paragraph">
						<figure className="icon-container">
							<Users
								size={
									window.innerWidth >= 3840
										? 42
										: window.innerWidth >= 2560
										? 34
										: 28
								}
							/>
						</figure>
						<SlideIn direction="right" delay={130} threshold={0.1}>
							<ul className="paragraph-text">
								<li>
									<p>{t("advantages.paragraph-title")}</p>
								</li>
								<li>
									<p>{t("advantages.paragraph-text")}</p>
								</li>
							</ul>
						</SlideIn>
					</div>
					<div className="benefits-paragraph">
						<figure className="icon-container">
							<Handshake
								size={
									window.innerWidth >= 3840
										? 42
										: window.innerWidth >= 2560
										? 34
										: 28
								}
							/>
						</figure>
						<SlideIn direction="right" delay={130} threshold={0.1}>
							<ul className="paragraph-text">
								<li>
									<p>{t("advantages.benefits-title")}</p>
								</li>
								<li>
									<p>{t("advantages.benefits-text")}</p>
								</li>
							</ul>
						</SlideIn>
					</div>
					<div className="benefits-paragraph">
						<figure className="icon-container">
							<BellRinging
								size={
									window.innerWidth >= 3840
										? 42
										: window.innerWidth >= 2560
										? 34
										: 28
								}
							/>
						</figure>
						<SlideIn direction="right" delay={130} threshold={0.1}>
							<ul className="paragraph-text">
								<li>
									<p>{t("advantages.time-title")}</p>
								</li>
								<li>
									<p>{t("advantages.time-text")}</p>
								</li>
							</ul>
						</SlideIn>
					</div>
					<div className="button-container">
						<button className="paragraph-button" onClick={handleButtonClick}>
							<span>{t("advantages.paragraph-button")}</span>
							<svg
								className="arrow-icon"
								width={window.innerWidth >= 3840 ? "32" : "20"}
								height={window.innerWidth >= 3840 ? "32" : "20"}
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M4 10H16M16 10L11 5M16 10L11 15"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
					</div>
				</div>
				<div className="logo-benefits">
					<figure>
						<SlideIn direction="left" delay={200} threshold={0.1}>
							<div className="benefits-logo">
								<AnimatedImage src={Logo3d} alt="3d Logo" />
							</div>
						</SlideIn>
					</figure>
				</div>
			</div>
		</section>
	);
}

export default Advantages;

import { useEffect, useState, useRef } from "react";
import "./SlideIn.scss";

const SlideIn = ({
	children,
	delay = 0,
	duration = 400,
	direction = "up",
	threshold = 0.2,
	className = "", // Add className prop
}) => {
	const [isVisible, setIsVisible] = useState(false);
	const elementRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setTimeout(() => {
						setIsVisible(true);
					}, delay);
					observer.disconnect();
				}
			},
			{
				threshold: threshold,
				rootMargin: "50px",
			}
		);

		if (elementRef.current) {
			observer.observe(elementRef.current);
		}

		return () => {
			observer.disconnect();
		};
	}, [delay, threshold]);

	const getClassName = () => {
		const baseClass = "slide-in";
		const directionClass = `slide-in-${direction}`;
		const visibilityClass = isVisible ? "visible" : "";
		return `${baseClass} ${directionClass} ${visibilityClass} ${className}`.trim();
	};

	return (
		<div
			ref={elementRef}
			className={getClassName()}
			style={{
				transitionDelay: `${delay}ms`,
				transitionDuration: `${duration}ms`,
			}}
		>
			{children}
		</div>
	);
};

export default SlideIn;

import React, { useState } from "react";
import "./ContactStyles.scss";
import SvgFilterComponent from "./SvgFilterComponent";

function Contact() {
	const [activeButton, setActiveButton] = useState(null);

	const handleButtonClick = (index, href) => {
		setActiveButton(index);

		// Delay link
		setTimeout(() => {
			window.open(href, '_blank', 'noopener,noreferrer');
			//Reset active state after navigation
			setActiveButton(null);
		}, 100); // delay 
	};

	return (
		<section id="contact" className="contact-page">
			<div className="contact__container">
				<div className="container__social button-group">
					{[
						{
							icon: (
								<svg
									fill="#1A1A1A"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 64 64"
									width="512px"
									height="512px"
								>
									<path d="M 21.580078 7 C 13.541078 7 7 13.544938 7 21.585938 L 7 42.417969 C 7 50.457969 13.544938 57 21.585938 57 L 42.417969 57 C 50.457969 57 57 50.455062 57 42.414062 L 57 21.580078 C 57 13.541078 50.455062 7 42.414062 7 L 21.580078 7 z M 47 15 C 48.104 15 49 15.896 49 17 C 49 18.104 48.104 19 47 19 C 45.896 19 45 18.104 45 17 C 45 15.896 45.896 15 47 15 z M 32 19 C 39.17 19 45 24.83 45 32 C 45 39.17 39.169 45 32 45 C 24.83 45 19 39.169 19 32 C 19 24.831 24.83 19 32 19 z M 32 23 C 27.029 23 23 27.029 23 32 C 23 36.971 27.029 41 32 41 C 36.971 41 41 36.971 41 32 C 41 27.029 36.971 23 32 23 z" />
								</svg>
							),
							href: "https://www.instagram.com/gluck_esports/",
						},
						{
							icon: (
								<svg
									fill="#1A1A1A"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 50 50"
									width="512px"
									height="512px"
								>
									<path d="M25,2c12.703,0,23,10.297,23,23S37.703,48,25,48S2,37.703,2,25S12.297,2,25,2z M32.934,34.375	c0.423-1.298,2.405-14.234,2.65-16.783c0.074-0.772-0.17-1.285-0.648-1.514c-0.578-0.278-1.434-0.139-2.427,0.219	c-1.362,0.491-18.774,7.884-19.78,8.312c-0.954,0.405-1.856,0.847-1.856,1.487c0,0.45,0.267,0.703,1.003,0.966	c0.766,0.273,2.695,0.858,3.834,1.172c1.097,0.303,2.346,0.04,3.046-0.395c0.742-0.461,9.305-6.191,9.92-6.693	c0.614-0.502,1.104,0.141,0.602,0.644c-0.502,0.502-6.38,6.207-7.155,6.997c-0.941,0.959-0.273,1.953,0.358,2.351	c0.721,0.454,5.906,3.932,6.687,4.49c0.781,0.558,1.573,0.811,2.298,0.811C32.191,36.439,32.573,35.484,32.934,34.375z" />
								</svg>
							),
							href: "https://t.me/gluck_es",
						},
						{
							icon: (
								<svg
									fill="#1A1A1A"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 50 50"
									width="512px"
									height="512px"
								>
									<path d="M 14 4 C 8.4886661 4 4 8.4886661 4 14 L 4 36 C 4 41.511334 8.4886661 46 14 46 L 36 46 C 41.511334 46 46 41.511334 46 36 L 46 14 C 46 8.4886661 41.511334 4 36 4 L 14 4 z M 13 16 L 37 16 C 37.18 16 37.349766 16.020312 37.509766 16.070312 L 27.679688 25.890625 C 26.199688 27.370625 23.790547 27.370625 22.310547 25.890625 L 12.490234 16.070312 C 12.650234 16.020312 12.82 16 13 16 z M 11.070312 17.490234 L 18.589844 25 L 11.070312 32.509766 C 11.020312 32.349766 11 32.18 11 18 C 11 17.82 11.020312 17.650234 11.070312 17.490234 z M 38.929688 17.490234 C 38.979688 17.650234 39 17.82 39 18 L 39 32 C 39 32.18 38.979687 32.349766 38.929688 32.509766 L 31.400391 25 L 38.929688 17.490234 z M 20 26.410156 L 20.890625 27.310547 C 22.020625 28.440547 23.510234 29 24.990234 29 C 26.480234 29 27.959844 28.440547 29.089844 27.310547 L 29.990234 26.410156 L 37.509766 33.929688 C 37.349766 33.979688 37.18 34 37 34 L 13 34 C 12.82 34 12.650234 33.979687 12.490234 33.929688 L 20 26.410156 z" />
								</svg>
							),
							href: "https://mail.google.com/mail/?view=cm&fs=1&to=egor.morin@gmail.com",
						},
					].map((item, index) => (
						<div
							key={index}
							className={`social-button ${
								activeButton === index ? "active" : ""
							}`}
							onClick={() => handleButtonClick(index, item.href)}
						>
							{item.icon}
						</div>
					))}
				</div>
			</div>
			<SvgFilterComponent />
		</section>
	);
}

export default Contact;
import React from "react";
import "./ActivityStyles.scss";
import ActivityLogo1 from "../assets/activity-logo1.png";
import ActivityLogo2 from "../assets/activity-logo2.png";
import ActivityLogo3 from "../assets/activity-logo3.svg";
import SlideIn from "./SlideIn.js";
import { useTranslation } from "react-i18next";

function Activity() {
	const { t } = useTranslation();
	return (
		<section id="activity" className="activity">
			<div className="activity-heading">
				<div className="heading-text">
					<SlideIn direction="up" delay={100} threshold={0.2}>
						<h1>{t("activity.title")}</h1>
						<p className="activity-text">{t("activity.text")}</p>
					</SlideIn>
				</div>
			</div>
			<div className="activity-cards">
				<SlideIn direction="up" delay={150} threshold={0.1}>
					<div className="cards-container">
						<div className="container-card">
							<div className="card-logo">
								<div className="logo-container">
									<img src={ActivityLogo1} alt="ActivityLogo1" />
								</div>
							</div>
							<div className="card-text">
								<h3>{t("activity.card-heading")}</h3>
								<p>{t("activity.card-text")}</p>
							</div>
						</div>

						<div className="container-card">
							<div className="card-logo">
								<div className="logo-container">
									<img src={ActivityLogo2} alt="ActivityLogo2" />
								</div>
							</div>
							<div className="card-text">
								<h3>{t("activity.card-heading2")}</h3>
								<p>{t("activity.card-text2")}</p>
							</div>
						</div>

						<div className="container-card">
							<div className="card-logo">
								<div className="logo-container">
									<img src={ActivityLogo3} alt="ActivityLogo3" />
								</div>
							</div>
							<div className="card-text">
								<h3>{t("activity.card-heading3")}</h3>
								<p>{t("activity.card-text3")}</p>
							</div>
						</div>
					</div>
				</SlideIn>
			</div>
		</section>
	);
}

export default Activity;

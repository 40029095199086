import React from 'react';
import './AnimatedImage.scss';

const AnimatedImage = ({ src, alt }) => {
  return (
    <img 
      src={src} 
      alt={alt} 
      className="animated-image" 
    />
  );
};

export default AnimatedImage;
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./NavbarStyle.scss";
import SiteLogo from "../assets/logo-site.png";
import SiteLogoMobile from "../assets/logo-site-mobile.svg";
import { List, X } from "@phosphor-icons/react";
import ParallaxNavbar from "./ParallaxNavbar";

function useWindowWidth() {
	const [width, setWidth] = useState(() => window.innerWidth);

	useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return width;
}

function Navbar({ isVisible }) {
	const width = useWindowWidth();
	const isMobile = width <= 768;
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const { t, i18n } = useTranslation();

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const changeLanguage = (event) => {
		i18n.changeLanguage(event.target.value);
	};

	const languages = [
		{ code: "en", name: "EN" },
		{ code: "ru", name: "RU" },
		{ code: "es", name: "ES" },
		{ code: "pt", name: "PT" },
	];

	return (
		<ParallaxNavbar isVisible={isVisible}>
				<div className="navbar-content">
					<div className="logo">
						<a href="/" className="logo-link">
							<img src={isMobile ? SiteLogoMobile : SiteLogo} alt="SiteLogo" />
						</a>
					</div>

					{!isMobile && (
						<ul className="nav-menu">
							<li>
								<a href="/about">{t("about")}</a>
							</li>
							<li>
								<a href="/calculate">{t("calculate.title")}</a>
							</li>
							<li className="language-selector">
								<select onChange={changeLanguage} value={i18n.language}>
									{languages.map((lang) => (
										<option key={lang.code} value={lang.code}>
											{lang.name}
										</option>
									))}
								</select>
							</li>
						</ul>
					)}

					{isMobile && (
						<div className="mobile-controls">
							<div className="language-selector mobile">
								<select onChange={changeLanguage} value={i18n.language}>
									{languages.map((lang) => (
										<option key={lang.code} value={lang.code}>
											{lang.name}
										</option>
									))}
								</select>
							</div>
							<div className="hamburger" onClick={toggleMenu}>
								{isMenuOpen ? (
									<X size={32} color="#eeeeee" />
								) : (
									<List size={32} color="#eeeeee" />
								)}
							</div>
						</div>
					)}
				</div>
			{isMobile && (
				<div className={`nav-menu-mobile ${isMenuOpen ? "open" : ""}`}>
					<ul className="menu-mobile">
						<li>
							<a href="/about">{t("about")}</a>
						</li>
						<li>
							<a href="/calculate">{t("calculate.title")}</a>
						</li>
					</ul>
				</div>
			)}
		</ParallaxNavbar>
	);
}

export default Navbar;

const SvgFilterComponent = () => {
  return (
    <svg className="svg-filters">
      <defs>
        <radialGradient id="active" x1="0%" y1="0%" x2="100%" y2="0%">
          {/* color of icon */}
          <stop offset="0%" style={{stopColor:"#76cedb", stopOpacity:1}} /> 
          <stop offset="100%" style={{stopColor:"#76cedb", stopOpacity:1}} />
        </radialGradient>
        <filter id="inset-shadow">
          <feOffset dx='-1' dy='1' />
          <feGaussianBlur stdDeviation='.7' result='offset-blur' />
          <feComposite operator='out' in='SourceGraphic' in2='offset-blur' result='inverse' />
          <feFlood floodColor='black' floodOpacity='0.05' result='color' />
          <feComposite operator='in' in='color' in2='inverse' result='shadow' />
          <feComposite operator='over' in='shadow' in2='SourceGraphic' />
        </filter>
      </defs>
    </svg>
  );
};

export default SvgFilterComponent;
import React from "react";
import Marquee from "react-fast-marquee";
import "./TickerStyles.scss";
import image1 from "../assets/ticker-betboom3.png";
import image2 from "../assets/ticker-fonbet3.png";
import image3 from "../assets/ticker-pari3.png";
import image4 from "../assets/ticker-fissure3.png";
import image5 from "../assets/ticker-adrenaline3.png";
import image6 from "../assets/ticker-redbull3.png";
import image7 from "../assets/ticker-colizeum3.png";

function Ticker() {
	const images = [
		{ src: image1, alt: "Betboom logo" },
		{ src: image2, alt: "Fonbet logo" },
		{ src: image3, alt: "Pari logo" },
		{ src: image4, alt: "Fissure logo" },
		{ src: image5, alt: "Adrenaline logo" },
		{ src: image6, alt: "Red Bull logo" },
		{ src: image7, alt: "Colizeum logo" },
	];

	return (
		<div className="Ticker">
			<section>
				<Marquee
					speed={40} // Use the speed prop directly here
					gradient={false}
					style={{ display: "flex", alignItems: "center" }}
				>
					{images.map((img, index) => (
						<img
							key={index}
							src={img.src}
							alt={img.alt}
							className="ticker-image"
						/>
					))}
				</Marquee>
			</section>
		</div>
	);
}

export default Ticker;

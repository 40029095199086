import React from "react";
import "./HeroStyles.scss";
import Ticker from "./Ticker";
import { useTranslation } from "react-i18next";
import SlideIn from "./SlideIn.js";

function Hero() {
	const { t } = useTranslation();

	return (
		<section id="hero" className="hero-section">
			<div className="nav-heading">
				<SlideIn direction="up" delay={80}>
					<h1>{t("hero.title")}</h1>
				</SlideIn>
				<SlideIn direction="up" delay={130}>
					<p>{t("hero.subtitle")}</p>
				</SlideIn>
			</div>
			<div className="work-with">
				<div className="nav-sd-heading">
					<SlideIn direction="up" delay={200}>
						<h2>{t("hero.workWith")}</h2>
					</SlideIn>
				</div>
				<SlideIn delay={200}>
					<div className="ticker-container">
						<Ticker speed={55} />
					</div>
				</SlideIn>
			</div>
		</section>
	);
}

export default Hero;

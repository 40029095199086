import React, { useEffect } from "react";
import "./AboutStyles.scss";
import Navbar from "./Navbar";
import Footer from "./Footer";
import SlideIn from "./SlideIn.js";
import { useTranslation } from "react-i18next";
function About() {
	useEffect(() => {
		document.title = "About us | GLuck Esports";
	})
	const { t } = useTranslation();
	return (
		<section id="about" className="about-page">
			<Navbar isVisible={true} />
			<div className="about__container">
				<div className="about__text">
					<SlideIn direction="up" delay={80} threshold={0.1}>
						<p>
							<div dangerouslySetInnerHTML={{ __html: t("aboutPage.text") }} />
						</p>
					</SlideIn>
				</div>
			</div>
			<Footer alwaysVisible={true} />
		</section>
	);
}

export default About;

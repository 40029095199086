// ParallaxNavbar.js
import React from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import SlideIn from "./SlideIn";

function useWindowWidth() {
	const [width, setWidth] = React.useState(window.innerWidth);
	
	React.useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return width;
}

const ParallaxNavbar = ({ children, isVisible }) => {
	const location = useLocation();
	const isMainPage = location.pathname === "/"; // Check if current page is the main page
	const width = useWindowWidth();

	const parallaxTranslateY = width >= 769 ? (isVisible ? 0 : (width >= 3840 ? -170 : -100)) : 0;

	return (
		<motion.nav
			className="navbar"
			initial={{ y: 0 }}
			animate={{ y: parallaxTranslateY }}
			transition={{ duration: 0.4, ease: "easeInOut" }}
			style={{
				position: "fixed",
				top: 0,
				left: 0,
				right: 0,
				zIndex: 1000,
			}}
		>
			{isMainPage ? (
				<SlideIn direction="down" delay={50}>
					{children}
				</SlideIn>
			) : (
				children
			)}
		</motion.nav>
	);
};

export default ParallaxNavbar;

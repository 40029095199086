import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';
import translationPT from './locales/pt/translation.json';
import translationRU from './locales/ru/translation.json';

const savedLanguage = localStorage.getItem('userLanguage');

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ru: {
        translation: translationRU,
      },
      en: {
        translation: translationEN,
      },
      pt: {
        translation: translationPT,
      },
      es: {
        translation: translationES,
      },
    },
    lng: savedLanguage || "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', (lng) => {
  localStorage.setItem('userLanguage', lng);
});

export default i18n;
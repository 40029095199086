import React, { useState, useEffect, useRef, useCallback } from "react";
import "../sass/style.scss";
import Header from "./Header";
import Navbar from "./Navbar";
import History from "./History";
import Activity from "./Activity";
import Advantages from "./Advantages";
import Gallery from "./Gallery";
import Footer from "./Footer";
import { VisibilityProvider } from "./VisibilityContext";
// import AnimatedSection from "./AnimatedSection";

function App() {
	useEffect(() => {
		document.title = "GLuck Esports";
	})
	const [isNavbarVisible, setIsNavbarVisible] = useState(true);
	const mainContentRef = useRef(null);
	const galleryRef = useRef(null);

	const controlNavbar = useCallback(() => {
		if (!galleryRef.current) return;

		const scrollY = window.scrollY;
		const galleryPosition = galleryRef.current.offsetTop;
		const offset = 400;

		setIsNavbarVisible(scrollY < galleryPosition - offset);
	}, []);

	const handleScroll = useCallback(() => {
		controlNavbar();

		const mainContent = mainContentRef.current;
		if (!mainContent) return;

		const scrollPosition = window.scrollY;
		const windowHeight = window.innerHeight;
		const documentHeight = document.documentElement.scrollHeight;
		const footerHeight = 850;
		const bottomThreshold = documentHeight - windowHeight - footerHeight;
		const activationThreshold = bottomThreshold - windowHeight * 0.5;
		const maxTranslateY =
			window.innerWidth >= 3840
				? 1100
				: window.innerWidth >= 2560
				? 450
				: window.innerWidth <= 576
				? 350
				: 300; // Default value for other resolutions

		let translateY = 0;

		if (scrollPosition > activationThreshold) {
			const progress =
				(scrollPosition - activationThreshold) /
				(bottomThreshold - activationThreshold);
			const easedProgress = Math.pow(progress, 3);
			translateY = -Math.min(easedProgress * footerHeight, maxTranslateY);
		}

		mainContent.style.transform = `translateY(${translateY}px)`;
	}, [controlNavbar]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		handleScroll();

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [handleScroll]);

	return (
		<VisibilityProvider>
			<div className="App">
				<Navbar isVisible={isNavbarVisible} />
				<div className="main-content">
					<Header />
					<Activity />
					<History />
					<Advantages />
					<div ref={galleryRef}>
						<Gallery />
					</div>
				</div>
				<Footer />
			</div>
		</VisibilityProvider>
	);
}

export default App;
